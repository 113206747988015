import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Proyectos de Desarrollo web con Drupal | asdloop" description="Algunos ejemplos de proyectos de desarrollo web con Drupal que hemos desarollado en los últimos años. Contacta con nosotros y cuéntanos tu proyecto."/>

  <section className="jumbotron bg-transparent pb-5" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <div className="col">
          <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-8 offset-md-2 col-sm-12">Algunos de nuestros <span class="text-primary">proyectos</span> Drupal</h1>
          <p className="lead mb-4 col-md-8 offset-md-2 col-sm-12">Ejemplos de proyectos que realizamos, tanto para cliente final como para alguna de las agencias internacionales con las que colaboramos.</p>
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pt-5 pb-5 bg-transparent overflow-hidden" id="features">
    <div className="row project-preview-right align-items-center pb-md-5 bg-light p-3">
      <div className="col-md-4">
        <img className="img-fluid" src="/images/helse-drupal-2.png" alt="miniatura web Helse en Drupal" />
      </div>
      <div className="col-md-5 m-md-auto mt-4 mt-md-0">
        <h2>
          <strong>Helse</strong>
        </h2>
        <p className="lead">Desarrollo de sitio web y plataforma de gestión de pacientes y ejercicios en Drupal 8.</p>
        <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/helse-drupal8">Ver Detalles →</a>
      </div>
    </div>
    <div className="row project-preview-left align-items-center pt-5 pb-md-5 bg-transparent p-3">
      <div className="col-md-6 m-md-auto order-md-5">
        <img className="img-fluid" src="/images/eaglemoss-drupal-2.png" alt="miniatura del proyecto Drupal" width={500} />
      </div>
      <div className="col-md-5 mt-4 mt-md-0">
        <h2>
          <strong>Eaglemoss</strong>
        </h2>
        <p className="lead">Desarrollo de e-commerce multi-tienda / multi-pais líder en el sector de figuras coleccionables.</p>
        <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/eaglemoss-drupal">Ver Detalles →</a>
      </div>
    </div>
   <div className="row project-preview-right align-items-center pt-5 pb-md-5 bg-light p-3">
      <div className="col-md-4">
        <img className="img-fluid" src="/images/dimarzio-drupal-2.png" alt="miniatura proyecto Dimarzio Drupal" />
      </div>
      <div className="col-md-5 m-md-auto mt-4 mt-md-0">
        <h2>
          <strong>Di Marzio</strong>
        </h2>
        <p className="lead">Desarrollo web para cliente lider en EEUU fabricante de pastillas y hardware para guitarras.</p>
        <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/dimarzio-drupal-8">Ver Detalles →</a>
      </div>
    </div>
    <div className="row project-preview-left align-items-center pt-5 pb-md-5 bg-transparent p-3">
      <div className="col-md-6 m-md-auto order-md-5">
        <img className="img-fluid" src="/images/chalalai-drupal-2.png" alt="previsualizacion web Drupal Chalalai" width={500} />
      </div>
      <div className="col-md-5 mt-4 mt-md-0">
        <h2>
          <strong>Chalalai Thai Massage Madrid</strong>
        </h2>
        <p className="lead">Desarrollo de sitio web con pasarela de pago y gestión de reservas para centro de masaje tailandés en Madrid.</p>
        <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/chalalai">Ver Detalles →</a>
      </div>
    </div>
    <div className="row project-preview-right align-items-center pt-5 pb-md-5 bg-light p-3">
      <div className="col-md-4">
        <img className="img-fluid" src="/images/tenias-drupal-2.png" alt="Vista miniatura web Drupal de Tenias" />
      </div>
      <div className="col-md-5 m-md-auto mt-4 mt-md-0">
        <h2>
          <strong>Tenías</strong>
        </h2>
        <p className="lead">Desarrollo de sitio web en Drupal 8 con buscador entre más de 3000 referencias de tractores.</p>
        <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/tenias-drupal8">Ver Detalles →</a>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default Projects
